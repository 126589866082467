import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	level2: {
		background: theme.palette.background.menu,
		height: "calc(100vh - 80px)",
		left: 0,
		maxHeight: 550,
		padding: theme.spacing(8, 10),
		position: "absolute",
		right: 0,
		top: 80,
		zIndex: 2000,
		"&:before": {
			background: theme.palette.background.menu,
			content: "''",
			height: "100%",
			left: -4000,
			position: "absolute",
			top: 0,
			width: 8000,
			zIndex: -1
		},
		[theme.breakpoints.up("lg")]: {
			height: "calc(100vh - 96px)",
			top: 96
		},
		"@media (min-width: 960px) and (min-height: 800px)": {
			height: "auto !important",
			minHeight: 300,
			maxHeight: "none !important"
		}
	},
	level2Fullscreen: {
		height: "calc(100vh - 104px)",
		top: 104,
		[theme.breakpoints.up("lg")]: {
			height: "calc(100vh - 150px)",
			top: 150
		},
		"@media (min-width: 960px) and (min-height: 800px)": {
			height: "auto !important",
			minHeight: 300,
			maxHeight: "none !important"
		}
	},
	container: {
		alignContent: "flex-start",
		display: "grid",
		gap: theme.spacing(2, 4),
		gridTemplateColumns: "270px 270px 270px",
		height: "100%",
		overflow: "auto",
		width: "100%",
		"@media (min-width: 960px) and (min-height: 800px)": {
			height: "auto !important"
		}
	}
}));

export default styles;
