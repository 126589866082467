import React, { useContext } from "react";
import clsx from "clsx";
import WebProfileContext from "@WebProfileContext";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";

import styles from "./Phone.styles";

const Phone = () => {
	const [profileData] = useContext(WebProfileContext);
	const { phone } = profileData;
	const css = styles();

	return (
		<a
			className={clsx(css.phone, "mco-view-component-header-phone")}
			href={"tel:" + phone?.replace(/ /g, "")}
		>
			<ReactSVGIcon src="/img/icons/phone.svg" size={20} />
			<span className={clsx(css.label, "mco-view-component-header-phone__label")}>
				{phone}
			</span>
		</a>
	);
};

export default Phone;
