import React, { useContext } from "react";
import clsx from "clsx";
import PageContext from "@PageContext";
import MenuItem from "../MenuItem";
import Level3 from "../Level3";

import styles from "./Level2.styles";

const Level2 = ({ handleMouseEnterLevel2, handleMouseLeaveLevel2, headerScroll, items }) => {
	const [pageData] = useContext(PageContext);
	const layoutVariant = pageData?.layoutVariant;
	const css = styles();

	return (
		<>
			<div
				className={clsx(css.level2, "mco-view-component-header-menu-desktopmenu-level2", {
					[css.level2Fullscreen]: layoutVariant !== "no-animation" && headerScroll === false
				})}
				onMouseEnter={handleMouseEnterLevel2}
				onMouseLeave={handleMouseLeaveLevel2}
			>
				<div className={css.container}>
					{items?.map(item => (
						<div
							key={item.id}
							className={clsx(
								css.wrapper,
								"mco-view-component-header-menu-desktopmenu-level2__wrapper"
							)}
						>
							<MenuItem item={item} level={2} />
							<Level3 items={item?.items} />
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default Level2;
