import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	phone: {
		alignItems: "center",
		color: "#FFFFFF",
		display: "flex",
		fontSize: 14,
		gap: theme.spacing(1),
		textTransform: "uppercase",
		"& svg path": {
			fill: "rgba(255,255,255,0.5)",
			fillOpacity: 1
		},
		"&:hover": {
			"& svg path": {
				fill: "rgba(255,255,255,1)"
			}
		}
	}
}));

export default styles;
