import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	jobs: {
		"& a": {
			alignItems: "center",
			display: "flex",
			gap: theme.spacing(1),
			textTransform: "uppercase",
			"& svg path": {
				fill: theme.palette.primary.dark,
				fillOpacity: 1
			}
		}
	}
}));

export default styles;
