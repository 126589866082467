import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	animation: {
		height: "50vh",
		minHeight: 300,
		"@media (min-width: 960px) and (max-height: 800px)": {
			minHeight: 450
		},
		"@media (min-width: 1280px) and (max-height: 800px)": {
			minHeight: 512
		},
		"& .mco-animation-01__texts": {
			display: "none",
			[theme.breakpoints.up("md")]: {
				display: "flex",
				flexDirection: "column-reverse",
				left: theme.spacing(5),
				opacity: "0 !important",
				transform: "translate(-10%, 0)",
				transition: "opacity 1500ms ease, transform 1000ms ease",
				"& .mco-animation-01__text--1": {
					color: "#FFFFFF",
					...theme.typography.h1,
					fontSize: "clamp(2rem, 5vw, 64px) !important",
					lineHeight: 1.125,
					"@media (min-width: 960px) and (max-height: 700px)": {
						fontSize: "clamp(1.5rem, 5vw, 40px) !important"
					}
				},
				"& .mco-animation-01__text--2": {
					color: "#FFFFFF",
					marginBottom: 2,
					...theme.typography.overline
				}
			},
			[theme.breakpoints.up("lg")]: {
				left: theme.spacing(17)
			}
		},
		"&.mco-view-component-animation--text-visible": {
			"& .mco-animation-01__texts": {
				opacity: "1 !important",
				transform: "translate(0, 0)"
			}
		},
		"& .mco-animation-01__bullets": {
			[theme.breakpoints.up("lg")]: {
				bottom: theme.spacing(10),
				justifyContent: "flex-start",
				left: theme.spacing(17)
			},
			"@media (min-width: 960px) and (max-height: 700px)": {
				bottom: theme.spacing(10)
			},
			"& .mco-animation-01__bullet": {
				background: theme.palette.primary.main,
				opacity: 0.5,
				height: 8,
				width: 8,
				[theme.breakpoints.up("md")]: {
					background: "#FFFFFF"
				}
			},
			"& .mco-animation-01__bullet--selected": {
				opacity: 1,
				width: 16
			}
		}
	},
	fullscreen: {
		height: "50vh",
		minHeight: 300,
		maxHeight: 400,
		[theme.breakpoints.up("md")]: {
			height: "calc(var(--vh, 1vh) * 100)",
			display: "flex",
			flexDirection: "column",
			maxHeight: "unset",
			"& .mco-animation-01__slider:before": {
				background: "linear-gradient(90deg, #164F69 33.74%, rgba(22, 79, 105, 0) 100%)",
				content: "''",
				height: "100%",
				opacity: 0.6,
				left: 0,
				position: "absolute",
				top: 0,
				width: "75%",
				zIndex: 2
			},
			"& .mco-animation-01__slide-item": {
				zIndex: "unset"
			},
			"& .mco-animation-01__bullets": {
				[theme.breakpoints.up("lg")]: {
					bottom: theme.spacing(19.5)
				},
				"@media (min-width: 960px) and (max-height: 700px)": {
					bottom: theme.spacing(10)
				}
			}
		}
	},
	headerVisible: {
		marginTop: -72,
		[theme.breakpoints.up("md")]: {
			marginTop: -115
		},
		[theme.breakpoints.up("lg")]: {
			marginTop: -131
		}
	}
}));

export default styles;
