import loadable from "@loadable/component";

const Container = loadable(() => import("@Components/views/Container"));
const Form = loadable(() => import("@micado-digital/react-form"));
const Connector = loadable(() => import("@micado-digital/react-form/Connector"));
const ReactHeadline01 = loadable(() =>
	import("@micado-digital/react-headline/ReactHeadline01")
);
const ReactText01 = loadable(() => import("@micado-digital/react-text/ReactText01"));
const ReactList01 = loadable(() => import("@micado-digital/react-list/ReactList01"));
const ReactTable01 = loadable(() => import("@micado-digital/react-table/ReactTable01"));
const ReactReferences01 = loadable(() =>
	import("@micado-digital/react-references/ReactReferences01")
);
const ReactSVGIcon = loadable(() => import("@micado-digital/react-svg-icon/ReactSVGIcon"));
const ReactVideo02 = loadable(() => import("@micado-digital/react-video/ReactVideo02"));
const ReactHTML = loadable(() => import("@micado-digital/react-html/ReactHTML"));
const ReactFile01 = loadable(() => import("@micado-digital/react-file/ReactFile01"));
const ReactCopyrights01 = loadable(() =>
	import("@micado-digital/react-copyrights/ReactCopyrights01")
);
const ReactDropDown01 = loadable(() =>
	import("@micado-digital/react-dropdown/ReactDropDown01")
);
const ReactSingleImage01 = loadable(() =>
	import("@micado-digital/react-singleimage/ReactSingleImage01")
);
const ReactImageText01 = loadable(() =>
	import("@micado-digital/react-imagetext/ReactImageText01")
);
const ReactGallery03 = loadable(() => import("@micado-digital/react-gallery/ReactGallery03"));
const ReactSingleTeaser01 = loadable(() =>
	import("@micado-digital/react-singleteaser/ReactSingleTeaser01")
);
const ReactTeaserList01 = loadable(() =>
	import("@micado-digital/react-teaser-list/ReactTeaserList01")
);
const ReactTeaser01 = loadable(() => import("@micado-digital/react-teaser/ReactTeaser01"));
const ReactTeaser02 = loadable(() => import("@micado-digital/react-teaser/ReactTeaser02"));
const ReactSearchResults = loadable(() =>
	import("@micado-digital/react-search-results/ReactSearchResults")
);
const ReactEBookTeaser01 = loadable(() =>
	import("@micado-digital/react-ebook-teaser/ReactEBookTeaser01")
);
const ReactRoutePlanner = loadable(() =>
	import("@micado-digital/react-route-planner/ReactRoutePlanner")
);
const BabyGallery = loadable(() => import("./BabyGallery"));
const IconTeaser = loadable(() => import("./Teaser/IconTeaser"));
const TeaserIntroCombo = loadable(() => import("./TeaserIntroCombo"));
const InfoboxCombo = loadable(() => import("./InfoboxCombo"));
const InfoboxBenefitsCombo = loadable(() => import("./InfoboxBenefitsCombo"));
const Team = loadable(() => import("./Team"));
const Employee = loadable(() => import("./Team/Employee"));
const Contact = loadable(() => import("./Contact"));
const ContactItem = loadable(() => import("./Contact/ContactItem"));
const Infobox = loadable(() => import("./Infobox"));
const InfoboxBenefits = loadable(() => import("./InfoboxBenefits"));
const Jobs = loadable(() => import("./Jobs"));
const LoginForm = loadable(() => import("./Login"));
const ShareAndPrint = loadable(() => import("./ShareAndPrint"));

const getJSXElement = (element, lang, search, sysLink) => {
	const { REACT_APP_PATH } = process.env;
	const {
		addition,
		addition2,
		anchor,
		children,
		elements,
		id,
		media,
		option,
		option2,
		reference,
		tag,
		teaser,
		text = "",
		textcontent,
		teasergroup,
		title = "",
		variant
	} = element;

	switch (tag) {
		case "basic-headline": {
			return (
				<Container
					key={id}
					tag={tag}
					variant={variant === 6 ? "overline" : (variant || 0) + 1}
					anchor={anchor?.tag}
				>
					<ReactHeadline01 text={text} variant={variant === 6 ? 5 : variant} />
				</Container>
			);
		}

		case "basic-text": {
			return (
				<Container
					key={id}
					tag={tag}
					anchor={anchor?.tag}
					variant={variant === 1 ? "justify" : ""}
				>
					<ReactText01 text={text} />
				</Container>
			);
		}

		case "basic-list": {
			return (
				<Container key={id} tag={tag} maxWidth="md" anchor={anchor?.tag}>
					<ReactList01
						headlineVariant="h6"
						icon={<ReactSVGIcon color="primary" src="/img/icons/check.svg" size={24} />}
						textcontent={textcontent}
						title={title}
						variant={variant}
					/>
				</Container>
			);
		}

		case "basic-imagetext": {
			return (
				<Container key={id} tag={tag} anchor={anchor?.tag}>
					<ReactImageText01
						addition2={addition2}
						alt={addition}
						headlineVariant="h3"
						option={option}
						media={media ? media?.[0] : {}}
						mediaFormats={{
							xs: "basic-imagetext-mobile",
							sm: "basic-imagetext"
						}}
						mediaPath={REACT_APP_PATH}
						title={title}
						text={text}
					/>
				</Container>
			);
		}

		case "basic-gallery": {
			const { elements } = element;

			return (
				<Container key={id} tag={tag} anchor={anchor?.tag}>
					<ReactGallery03
						breakpoints={{
							xs: 1,
							sm: 2,
							md: 3
						}}
						elements={elements}
						lang={"de"}
						mediaFormats={{
							xs: "basic-gallery-thumb"
						}}
						overlayMediaFormats={{
							xs: "basic-gallery"
						}}
						spacing={24}
					/>
				</Container>
			);
		}

		case "basic-table": {
			const newTableArray = [];

			for (const entries of textcontent?.items) {
				const rows = entries?.items;
				const columns = rows.map(column => {
					const columnObj = {
						text: column?.text,
						align: column?.["attr-align"],
						width: column?.["attr-width"]
					};
					return columnObj;
				});

				newTableArray.push(columns);
			}

			return (
				<Container key={id} tag={tag} maxWidth="md" anchor={anchor?.tag}>
					<ReactTable01
						addition={addition}
						headlineVariant="h6"
						items={newTableArray}
						title={title}
						sticky={variant === 1 ? true : false}
					/>
				</Container>
			);
		}

		case "basic-links": {
			return (
				<Container key={id} tag={tag} maxWidth="md" anchor={anchor?.tag}>
					<ReactReferences01
						elements={elements}
						title={title}
						startIcon={<ReactSVGIcon src="/img/icons/chevron-right.svg" size={20} />}
						hasIcon={false}
						headlineVariant={variant === 1 ? "h6" : "h3"}
					/>
				</Container>
			);
		}

		case "basic-video": {
			return (
				<Container key={id} tag={tag} anchor={anchor?.tag}>
					<ReactVideo02
						media={media[0]}
						mediaFormats={{ xs: "video-mobile", sm: "video" }}
						mediaPath={REACT_APP_PATH}
						title={title}
						text={text}
					/>
				</Container>
			);
		}

		case "basic-html": {
			return <ReactHTML key={id} html={text} anchor={anchor?.tag} />;
		}

		case "basic-download-group": {
			return (
				<Container key={id} tag={tag} maxWidth="md" anchor={anchor?.tag}>
					{children?.map(item => getJSXElement(item))}
				</Container>
			);
		}

		case "basic-download": {
			return (
				<Container key={id} tag={tag} maxWidth="md" anchor={anchor?.tag}>
					<ReactFile01 media={media} title={title} />
				</Container>
			);
		}

		case "basic-copyrights": {
			return (
				<Container key={id} tag={tag} maxWidth="md" anchor={anchor?.tag}>
					<ReactCopyrights01 />
				</Container>
			);
		}

		case "basic-dropdown": {
			return null;
		}

		case "basic-dropdown-group": {
			return (
				<Container
					key={id}
					tag={tag}
					maxWidth="md"
					anchor={element?.children?.[0]?.anchor?.tag}
				>
					<ReactDropDown01
						option={element?.children?.[0]?.option2}
						title={element?.children?.[0]?.title}
					>
						<>
							{children
								?.filter(item => item.tag !== "basic-dropdown")
								?.map(item => getJSXElement(item))}
						</>
					</ReactDropDown01>
				</Container>
			);
		}

		case "basic-singleteaser": {
			return (
				<Container
					key={id}
					tag={tag}
					variant={variant === 1 ? "background" : ""}
					anchor={anchor?.tag}
				>
					<ReactSingleTeaser01
						buttonLabel="Mehr erfahren"
						buttonVariant="contained"
						headlineVariant="h3"
						icon="/img/icons/chevron-right.svg"
						teaser={teaser}
						media={teaser?.media?.[0]}
						mediaPath={REACT_APP_PATH}
						mediaFormats={{
							xs: "basic-teaser-mobile",
							sm: "basic-teaser"
						}}
						variant={1}
					/>
				</Container>
			);
		}

		case "basic-external-singleteaser": {
			const addition = textcontent?.items?.find(item => item?.name === "valAddition")?.text;
			const title = textcontent?.items?.find(item => item?.name === "valTitle")?.text;
			const text = textcontent?.items?.find(item => item?.name === "valText")?.text;
			const link = textcontent?.items?.find(item => item?.name === "valUrl")?.text;
			const media = elements?.find(item => item?.tag === "valImage")?.media;

			const teaser = {
				addition: addition,
				title: title,
				text: text,
				link: link,
				linkTarget: "_blank"
			};

			return (
				<Container key={id} tag={tag} anchor={anchor?.tag}>
					<ReactSingleTeaser01
						buttonLabel="Mehr erfahren"
						buttonVariant="contained"
						headlineVariant="h3"
						icon="/img/icons/chevron-right.svg"
						teaser={teaser}
						media={media?.[0]}
						mediaPath={REACT_APP_PATH}
						mediaFormats={{
							xs: "basic-teaser-mobile",
							sm: "basic-teaser"
						}}
						variant={1}
					/>
				</Container>
			);
		}

		case "basic-singleimage": {
			return (
				<Container
					key={id}
					tag={tag}
					anchor={anchor?.tag}
					variant={variant === 1 ? "uncut" : ""}
				>
					<ReactSingleImage01
						addition={addition}
						option2={option2}
						media={media ? media?.[0] : {}}
						mediaFormats={{
							xs: variant === 1 ? "basic-singleimage-sub-mobile" : "basic-singleimage-mobile",
							sm: variant === 1 ? "basic-singleimage-sub" : "basic-singleimage"
						}}
						mediaPath={REACT_APP_PATH}
						reference={reference}
						title={title}
						text={text}
					/>
				</Container>
			);
		}

		case "basic-form": {
			return (
				<Container key={id} maxWidth="md" tag={tag} anchor={anchor?.tag}>
					<Form action={`${REACT_APP_PATH}/submitform.json.api`} method="POST" key={id}>
						<Connector formElements={elements} formID={id} />
					</Form>
				</Container>
			);
		}

		case "basic-teaser": {
			const breakpoints = {
				0: {
					slidesPerView: 1.1,
					spaceBetween: 24
				},
				600: {
					slidesPerView: 2.1,
					spaceBetween: 24
				},
				900: {
					slidesPerView: 2.5,
					spaceBetween: 24
				},
				1024: {
					slidesPerView: 3,
					spaceBetween: 24
				}
			};

			if (variant === 1) {
				return (
					<Container key={id} tag={tag} variant="grid" maxWidth={false} anchor={anchor?.tag}>
						<Container>
							<ReactTeaserList01
								gridBreakpoints={{
									xs: 12,
									sm: 6,
									md: 4
								}}
								spacing={3}
							>
								{teasergroup?.items?.map(({ id, link, media, title, linkTarget }) => {
									return (
										<ReactTeaser02
											key={id}
											link={link}
											media={media ? media?.[0] : {}}
											mediaFormats={{
												xs: "basic-teaser-grid"
											}}
											mediaPath={REACT_APP_PATH}
											target={linkTarget}
											title={title ? title : ""}
										/>
									);
								})}
							</ReactTeaserList01>
						</Container>
					</Container>
				);
			} else if (variant === 2) {
				return (
					<Container
						key={id}
						tag={tag}
						variant="grid-4-entries"
						maxWidth={false}
						anchor={anchor?.tag}
					>
						<Container>
							<ReactTeaserList01
								gridBreakpoints={{
									xs: 12,
									sm: 6,
									md: 4,
									lg: 3
								}}
								spacing={3}
							>
								{teasergroup?.items?.map(({ id, link, media, title, linkTarget }) => {
									return (
										<ReactTeaser02
											key={id}
											link={link}
											media={media ? media?.[0] : {}}
											mediaFormats={{
												xs: "basic-teaser-grid"
											}}
											mediaPath={REACT_APP_PATH}
											target={linkTarget}
											title={title ? title : ""}
										/>
									);
								})}
							</ReactTeaserList01>
						</Container>
					</Container>
				);
			} else {
				return (
					<Container key={id} tag={tag} maxWidth={false} variant="slider" anchor={anchor?.tag}>
						<Container>
							<ReactTeaserList01
								autoplay={false}
								button={{
									label: "Alle Einträge sehen",
									link: reference?.pageName,
									position: "bottom",
									size: "small",
									variant: "outlined"
								}}
								delay={5000}
								headline={title}
								loop={false}
								showPagination={true}
								showNavigation={true}
								navNext="/img/icons/chevron-right.svg"
								navPrev="/img/icons/chevron-left.svg"
								sliderBreakpoints={breakpoints}
								speed={1000}
								variant="slider"
							>
								{teasergroup?.items?.map(
									({ id, link, media, title, text, linkTarget, from, to }) => {
										return (
											<ReactTeaser01
												button={"Mehr erfahren"}
												buttonIcon="/img/icons/chevron-right.svg"
												buttonIconSize={20}
												buttonSize="small"
												buttonVariant="contained"
												date={{ dateFrom: from, dateTo: to }}
												key={id}
												link={link}
												linkElement={true}
												media={media ? media?.[0] : {}}
												mediaFormats={{
													xs: "basic-teaser-mobile",
													sm: "basic-teaser-slider"
												}}
												mediaPath={REACT_APP_PATH}
												target={linkTarget}
												text={text}
												title={title ? title : ""}
											/>
										);
									}
								)}
							</ReactTeaserList01>
						</Container>
					</Container>
				);
			}
		}

		case "extension-icon-teaser": {
			if (!elements || elements?.length === 0) {
				return;
			}

			const breakpoints = {
				0: {
					slidesPerView: 1.1,
					spaceBetween: 16
				},
				600: {
					slidesPerView: 2.1,
					spaceBetween: 24
				},
				900: {
					slidesPerView: 2.5,
					spaceBetween: 24
				},
				1024: {
					slidesPerView: 3,
					spaceBetween: 24
				}
			};

			return (
				<Container key={id} tag={tag} anchor={anchor?.tag}>
					<ReactTeaserList01
						autoplay={false}
						delay={5000}
						loop={false}
						showPagination={false}
						showNavigation={true}
						navNext="/img/icons/chevron-right.svg"
						navPrev="/img/icons/chevron-left.svg"
						sliderBreakpoints={breakpoints}
						speed={1000}
						variant="slider"
					>
						{elements?.map(item => {
							const title = item?.textcontent?.items?.find(
								item => item?.name === "valTitle"
							)?.text;
							const text = item?.textcontent?.items?.find(
								item => item?.name === "valText"
							)?.text;
							const link = item?.elements?.find(item => item?.tag === "valLink")?.reference
								?.pageName;
							const icon = item?.elements?.find(item => item?.tag === "valIcon")?.media?.[0]
								?.filename;

							return (
								<IconTeaser
									key={item?.id}
									title={title ? title : ""}
									text={text}
									link={link}
									icon={icon}
								/>
							);
						})}
					</ReactTeaserList01>
				</Container>
			);
		}

		case "extension-teaser-combo": {
			const { children } = element;

			return (
				<TeaserIntroCombo key={id} tag={tag} anchor={anchor?.tag}>
					{children?.map(item => getJSXElement(item))}
				</TeaserIntroCombo>
			);
		}

		case "extension-infobox-combo": {
			const { children } = element;

			return (
				<InfoboxCombo key={id} tag={tag} anchor={anchor?.tag}>
					{children?.map(item => getJSXElement(item))}
				</InfoboxCombo>
			);
		}

		case "extension-infobox-benefits-combo": {
			const { children } = element;

			return (
				<InfoboxBenefitsCombo key={id} tag={tag} anchor={anchor?.tag}>
					{children?.map(item => getJSXElement(item))}
				</InfoboxBenefitsCombo>
			);
		}

		case "extension-team": {
			if (!elements) {
				return;
			}
			const headline = textcontent?.items?.find(item => item?.name === "valDepartment")?.text;

			return (
				<Container key={id} tag={tag} maxWidth="md" anchor={anchor?.tag}>
					<Team headline={headline}>
						{elements?.map(item => {
							const name = item?.textcontent?.items?.find(
								item => item?.name === "valName"
							)?.text;
							const mail = item?.textcontent?.items?.find(
								item => item?.name === "valEmail"
							)?.text;
							const media = item?.elements?.find(item => item?.tag === "valPortrait")?.media;

							return <Employee key={item?.id} name={name} mail={mail} media={media?.[0]} />;
						})}
					</Team>
				</Container>
			);
		}

		case "extension-contact": {
			if (!elements) {
				return;
			}

			const headline = textcontent?.items?.find(item => item?.name === "valHeadline")?.text;

			return (
				<Container key={id} tag={tag} anchor={anchor?.tag} maxWidth={false}>
					<Container>
						<Contact headline={headline}>
							{elements?.map(item => {
								const button = item?.textcontent?.items?.find(
									item => item?.name === "valButton"
								)?.text;
								const title = item?.textcontent?.items?.find(
									item => item?.name === "valTitle"
								)?.text;
								const phone = item?.textcontent?.items?.find(
									item => item.name === "valPhone"
								)?.text;
								const phoneLink = item?.textcontent?.items?.find(
									item => item.name === "valPhoneLink"
								)?.text;
								const mail = item?.textcontent?.items?.find(
									item => item.name === "valEmail"
								)?.text;
								const url = item?.elements?.find(item => item?.tag === "valLink")?.media?.[0]
									?.filename;

								return (
									<ContactItem
										key={item?.id}
										button={button}
										title={title}
										phone={phone}
										phoneLink={phoneLink}
										mail={mail}
										url={url}
									/>
								);
							})}
						</Contact>
					</Container>
				</Container>
			);
		}

		case "extension-babygallery": {
			return (
				<Container key={id} tag={tag} maxWidth="md" anchor={anchor?.tag}>
					<BabyGallery />
				</Container>
			);
		}

		case "extension-infobox": {
			if (!elements) {
				return;
			}

			const headline = textcontent?.items?.find(item => item?.name === "valHeadline")?.text;
			const media = elements?.find(item => item?.tag === "valImage")?.media;
			const items = elements?.filter(item => item?.tag === "extension-infobox-entry");
			const buttonLabel = textcontent?.items?.find(
				item => item?.name === "valButtonLabel"
			)?.text;
			const buttonLink = elements?.find(item => item?.tag === "valLink")?.media?.[0]?.filename;
			const buttonTarget = textcontent?.items?.find(item => item?.name === "valTarget")?.text;
			const buttonDownload = textcontent?.items?.find(
				item => item?.name === "valDownload"
			)?.text;

			return (
				<Container key={id} tag={tag} maxWidth={false} anchor={anchor?.tag}>
					<Container>
						<Infobox
							button={{
								download: buttonDownload === "True" ? true : false,
								label: buttonLabel,
								link: buttonLink,
								target: buttonTarget === "True" ? "_blank" : "_top"
							}}
							headline={headline}
							items={items}
							media={media?.[0]}
						/>
					</Container>
				</Container>
			);
		}

		case "extension-benefits": {
			if (!elements) {
				return;
			}

			const headline = textcontent?.items?.find(item => item?.name === "valHeadline")?.text;
			const media = elements?.find(item => item?.tag === "valImage")?.media;
			const items = elements?.filter(item => item?.tag === "extension-benefits-entry");
			const buttonLabel = textcontent?.items?.find(
				item => item?.name === "valButtonLabel"
			)?.text;
			const buttonLink = elements?.find(item => item?.tag === "valLink")?.media?.[0]?.filename;
			const buttonTarget = textcontent?.items?.find(item => item?.name === "valTarget")?.text;
			const buttonDownload = textcontent?.items?.find(
				item => item?.name === "valDownload"
			)?.text;

			return (
				<Container key={id} tag={tag} maxWidth={false} anchor={anchor?.tag}>
					<Container>
						<InfoboxBenefits
							button={{
								download: buttonDownload === "True" ? true : false,
								label: buttonLabel,
								link: buttonLink,
								target: buttonTarget === "True" ? "_blank" : "_top"
							}}
							headline={headline}
							items={items}
							media={media?.[0]}
						/>
					</Container>
				</Container>
			);
		}

		case "extension-jobs": {
			const title = textcontent?.items?.find(item => item?.name === "valTitle")?.text;
			const items = elements?.[0]?.teasergroup?.items;
			const icon = elements?.find(item => item?.tag === "valIcon")?.media?.[0]?.filename;

			return (
				<Container key={id} maxWidth="md" tag={tag} anchor={anchor?.tag}>
					<Jobs title={title} icon={icon} items={items} />
				</Container>
			);
		}

		case "extension-search-results": {
			return (
				<Container key={id} tag={tag}>
					<ReactSearchResults
						api={`${REACT_APP_PATH}/searchv2.json.api`}
						detailUrl={sysLink("search")}
						icon={<ReactSVGIcon src="/img/icons/arrow-right.svg" size={32} />}
					/>
				</Container>
			);
		}

		case "extension-login": {
			const username = textcontent?.items?.find(item => item?.name === "valUsername")?.text;
			const redirectURL = textcontent?.items?.find(item => item?.name === "valLink")?.text;
			const button = textcontent?.items?.find(item => item?.name === "valButton")?.text;

			return (
				<Container key={id} maxWidth="md" tag={tag}>
					<LoginForm
						button={button}
						username={username}
						redirectURL={`/${lang}/${redirectURL}`}
					/>
				</Container>
			);
		}

		case "extension-ebook": {
			let title = textcontent?.items?.find(e => e.name === "title")?.text;
			let text = textcontent?.items?.find(e => e.name === "text")?.text;
			let media = elements?.find(e => e.media)?.media?.[0];
			let filename = elements?.[0]?.media?.find(e => e.filename)?.filename;
			let lastPathName = filename?.substring(filename.lastIndexOf("/") + 1);
			let download = elements?.[0]?.media?.find(e => e.fileType === "PDF")?.filename;
			let browseURL = lastPathName?.replace(/\.[^/.]+$/, "") + `.html`;

			return (
				<Container key={id} maxWidth="md" tag={tag}>
					<ReactEBookTeaser01
						browse={`${REACT_APP_PATH}/ebook/${browseURL}`}
						browseIcon={"/img/icons/book.svg"}
						download={download}
						downloadIcon={"/img/icons/download.svg"}
						lang={lang}
						media={media ? media : {}}
						mediaFormats={{
							xs: "ebook-teaser"
						}}
						text={text}
						title={title}
					/>
				</Container>
			);
		}

		case "extension-route-planner": {
			return (
				<Container key={id} maxWidth={false} tag={tag}>
					<ReactRoutePlanner
						lang={lang}
						stroke={{
							width: 5
						}}
						startMarker={{
							anchor: [24, 48],
							popupAnchor: [0, -48],
							size: [48, 48],
							url: "/img/icons/marker-route-start.svg"
						}}
						targetMarker={{
							address: "Bahnhofstraße 14, 6380 St. Johann in Tirol",
							coords: [47.445252529229926, 12.394901514794924],
							marker: {
								anchor: [24, 48],
								popupAnchor: [0, -48],
								size: [48, 48],
								url: "/img/icons/marker-route-target.svg"
							},
							popup: {
								lat: 47.521047513951515,
								lng: 12.429615515213476,
								street: "Bahnhofstraße 14",
								title: "Bezirkskrankenhaus St. Johann in Tirol",
								town: "St. Johann in Tirol",
								zipCode: "6380"
							}
						}}
						token="5b3ce3597851110001cf6248b1c8ef453e3845b783eb4c91423a9e92"
					/>
				</Container>
			);
		}

		case "extension-share": {
			return (
				<Container maxWidth="md" key={id} tag={tag} anchor={anchor?.tag}>
					<ShareAndPrint lang={lang} />
				</Container>
			);
		}

		default:
			if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
				console.warn("missing element:", element);
			}
			return null;
	}
};

export default getJSXElement;
