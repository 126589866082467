import React from "react";
import clsx from "clsx";
import Container from "@Components/views/Container";
// import Education from "./Education";
// import Lang from "./Lang";
import Arrival from "./Arrival";
import Feedback from "./Feedback";
import Jobs from "./Jobs";
import Phone from "./Phone";

import styles from "./Row1.styles";

const Row1 = ({ layoutVariant }) => {
	const css = styles();

	return (
		layoutVariant !== "no-animation" && (
			<div className={clsx(css.row1, "mco-view-component-header-row1")}>
				<Container
					maxWidth="lg"
					className={clsx(css.container, "mco-view-component-header-row1__container")}
				>
					<Arrival />
					<Feedback />
					{/* <Education /> */}
					<Jobs />
					<Phone />
					{/* <Lang /> */}
				</Container>
			</div>
		)
	);
};

export default Row1;
