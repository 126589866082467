import React, { useContext } from "react";
import clsx from "clsx";
import WebProfileContext from "@WebProfileContext";
import Education from "./Education";
import Arrival from "./Arrival";
import Jobs from "./Jobs";
import Phone from "./Phone";

import styles from "./Links.styles";

const Links = () => {
	const [profileData] = useContext(WebProfileContext);
	const css = styles();

	const linkArrival = profileData?.customLinks?.find(
		item => item?.identifier === "header-arrival"
	);
	const linkEducation = profileData?.customLinks?.find(
		item => item?.identifier === "header-education"
	);
	const linkJobs = profileData?.customLinks?.find(item => item?.identifier === "header-jobs");

	return (
		<div className={clsx(css.links, "mco-view-component-header-menu-mobilemenu__links")}>
			<Arrival link={linkArrival} />
			<Education link={linkEducation} />
			<Jobs link={linkJobs} />
			<Phone />
		</div>
	);
};

export default Links;
