import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	links: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing(2),
		height: "100%",
		justifyContent: "center",
		position: "relative",
		"& span": {
			color: "#4F545C"
		},
		"&:before": {
			background:
				"linear-gradient(180deg, rgba(245,245,245,0.5) 0%, rgba(245,245,245,1) 100%)",
			content: "''",
			height: 100,
			left: 0,
			position: "absolute",
			pointerEvents: "none",
			width: "100%",
			top: -100
		}
	}
}));

export default styles;
