import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import PageContext from "@PageContext";
import ReactLink from "@micado-digital/react-link/ReactLink";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import useSystemLinks from "@micado-digital/react-ui/hooks/useSystemLinks";
import Local from "@Local";
import localDE from "@localDE";
import localEN from "@localEN";

import styles from "./Jobs.styles";

const Jobs = () => {
	const { REACT_APP_PATH } = process.env;
	const [pageData] = useContext(PageContext);
	const [countJobs, setCountJobs] = useState(0);
	const sysLink = useSystemLinks();
	const { lang = "de" } = pageData;
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});
	const css = styles();

	useEffect(() => {
		const fetchTeaser = async () => {
			const fetchURL =
				REACT_APP_PATH +
				`/Micado.WebEngine/Micado.WebEngine.Plugin.PageIO/List.api?lang=${lang}&teasergroups=26`;
			const response = await fetch(fetchURL);

			if (response.status !== 200) {
				throw new Error("Error");
			}

			const data = await response.json();

			setCountJobs(data?.entries);

			return data;
		};
		fetchTeaser();
	}, [lang, REACT_APP_PATH]);

	return (
		<div className={clsx(css.jobs, "mco-view-component-header-jobs")}>
			<ReactLink to={sysLink("header-jobs")}>
				<ReactSVGIcon src="/img/icons/jobs.svg" size={20} />
				<span className={clsx(css.label, "mco-view-component-header-water__jobs")}>
					<Local identifier="header.jobs" l={l} />
				</span>
				{countJobs > 0 && <span className={css.count}>{countJobs}</span>}
			</ReactLink>
		</div>
	);
};

export default Jobs;
