import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	level1: {
		alignItems: "center",
		display: "flex",
		gap: theme.spacing(2),
		"@media (min-width: 1100px)": {
			gap: theme.spacing(3)
		},
		[theme.breakpoints.up("lg")]: {
			gap: theme.spacing(4)
		}
	}
}));

export default styles;
